.curriculum-background {
  background-color: white;
  display: flex;
  flex-direction: row;
}
.left-side {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 66vw;
}

/* .profile-picture {
  width: 400px;
  height: 400px;
  border-radius: 50%;
} */

.profile-picture {
  width: 25vw;
  height: 25vw;
  border-radius: 50%;
}

.right-side {
  display: flex;
  justify-content: left;
  align-items: center;
  width: 33vw;
}

.left-side h1 {
  font-family: "Perpetua Titling MT Light", sans-serif;
  font-weight: bold;
  color: #084266;
  margin-top: 50px;
}

.title-line {
  width: 180px;
  height: 3px;
  background-color: #000;
  margin-bottom: 40px;
  margin-top: -5px;
}

.curriculum-text {
  width: 45vw;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 40px;
}

@media screen and (max-width: 1000px) {
  .profile-picture {
    width: 30vw;
    height: 30vw;
    margin-right: 50px;
  }
}

@media screen and (max-width: 600px) {
  .profile-picture {
    width: 30vw;
    height: 30vw;
    margin-right: 0px;
    margin-top: 20px;
  }

  .left-side {
    width: 100vw;
  }
  .right-side {
    width: 0px;
  }

  .left-side h1 {
    font-size: 20px;
  }

  .curriculum-text {
    width: 80vw;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 18px;
    text-align: justify;
    text-justify: inter-word;
    margin-bottom: 40px;
    font-size: 15px;
  }
}
