form {
  min-width: 70vw;
  margin: 0 auto;
}

label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px; /* Set the font size for labels */
}

input,
textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 16px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px; /* Set the font size for input and textarea */
}

input[type="submit"] {
  background-color: #4caf50;
  color: white;
  cursor: pointer;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

/* Optional: Add some additional styling for better aesthetics */
body {
  font-family: "Arial", sans-serif;
  background-color: #f4f4f4;
}

form {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}
