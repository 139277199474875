.slick-prev:before,
.slick-next:before {
  color: black;
  font-size: 40px;
  margin-left: -20px;
}

.slick-next:before {
  margin-left: 0px;
}

@media screen and (max-width: 530px) {
  .slider-container {
    margin-top: 15px;
    width: 100vw;
    margin-left: -20vw;
    overflow-x: hidden;
    min-height: 90vw;
  }
  .slick-prev:before,
  .slick-next:before {
    font-size: 20px;
  }
}
