
.background{
    background-color: white;
}

.gallery-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*align-items: center;*/
}

.gallery-container h1{
    font-family: "Perpetua Titling MT Light", sans-serif;
    font-weight: bold;
    color:  #084266;
    margin-top: 30px;
}

.title-line{
    width: 180px;
      height: 3px;
      background-color: #000;
      margin-bottom: 20px;
      margin-top: -5px;
    }

@media screen and (max-width:700px) {
    .backgroundPicture {
        background-size: auto;
        height: 10px;
        }

}

@media screen and (max-width:600px) {
    .gallery-container h1{
        font-size: 20px;
        margin-top: 20px;
      }

      .title-line{
        width: 100px;
          height: 3px;
          background-color: #000;
          margin-bottom: 40px;
          margin-top: 0px;
        }
}

@media screen and (max-width:530px) {

      .title-line{
        width: 100px;
          height: 3px;
          background-color: #000;
          margin-bottom: 40px;
          margin-top: 0px;
        }
}