.background-picture {
  background-image: url("../../assets/images/background_third_page.png");
  /* background-image: url("../../assets/images/porlezza_bg_header.jpg"); */
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
}

.specializations {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
}

.introduction-card {
  background-color: #f7f4f3;
  opacity: 80%;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.specializations h1 {
  font-family: "Perpetua Titling MT Light", sans-serif;
  font-weight: bold;
  color: #084266;
  margin-top: 30px;
}

.title-line-spec {
  width: 180px;
  height: 3px;
  background-color: #084266;
  margin-bottom: 20px;
  margin-top: -5px;
}

.specializations-text {
  width: 70vw;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  text-align: justify;
  text-justify: inter-word;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  padding-left: 30px;
  padding-right: 30px;
}

.topic-tab {
  background-color: white;
  /*border: 2px solid #0171bc;*/
  border-radius: 7px;
  margin-top: 30px;
}

.body-tab {
  width: 70vw;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  /*text-align: justify;*/
  /*text-justify: inter-word;*/
  display: flex;
  justify-content: space-around;
  padding-left: 10px;
  padding-right: 30px;
}

.element-body-tab {
  display: flex;
  flex-direction: column;
  justify-content: start;
  width: 27%;
  margin-top: 20px;
  margin-bottom: 50px;
  padding-left: 30px;
}

.element-body-tab span {
  display: flex;
  align-items: "center";
  height: 30px;
  padding-top: 20px;
}

.material-icons.md-36 {
  font-size: 30px;
  margin-top: -5px;
  color: #084266;
}

.material-icons.md-24 {
  font-size: 24px;
  color: #084266;
}

.icon-container {
  width: 30px;
  margin-right: 5px;
}

@media screen and (max-width: 1150px) {
  .element-body-tab {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 40%;
    margin-top: 20px;
    margin-bottom: 50px;
    padding-left: 30px;
    font-size: 15px;
  }
}

@media screen and (max-width: 800px) {
  .element-body-tab {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 70%;
    margin-top: 10px;
    margin-bottom: 50px;
    font-size: 15px;
    margin-left: -10px;
    margin-right: 5px;
  }

  .body-tab {
    width: 90vw;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 18px;
    /*text-align: justify;*/
    /*text-justify: inter-word;*/
    display: flex;
    justify-content: space-around;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media screen and (max-width: 600px) {
  .element-body-tab {
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 70%;
    margin-top: 10px;
    margin-bottom: 50px;
    font-size: 15px;
    margin-left: 15px;
    margin-right: 0px;
    padding-left: 0px;
  }

  .body-tab {
    min-height: 5vh;
  }

  .element-body-tab span {
    margin-bottom: 15px;
    min-height: 40px;
  }

  .introduction-card {
    margin-top: 20px;
  }

  .specializations h1 {
    font-size: 30px;
  }

  .specializations-text {
    font-size: 15px;
  }
}

@media screen and (max-width: 530px) {
  .element-body-tab {
    font-size: 14px;
  }
  .body-tab {
    min-width: 90vw;
  }
  .element-body-tab span {
    display: flex;
    align-items: "center";
    height: 50px;
    padding-top: 20px;
  }
  .specializations h1 {
    font-size: 20px;
  }

  .body-tab {
    width: 95vw;
    font-family: "Roboto Condensed", sans-serif;
    font-size: 15px;
    /*text-align: justify;*/
    /*text-justify: inter-word;*/
    display: flex;
    justify-content: space-around;
    padding-left: 10px;
    padding-right: 30px;
  }

  .background-picture {
    min-height: 80vh;
  }

  .element-body-tab span {
    margin-bottom: 40px;
  }

  .introduction-card {
    margin-top: 10px;
    padding-top: 0px;
  }

  .specializations h1 {
    margin-top: 10px;
  }
  .specializations-text {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .title-line {
    width: 180px;
    height: 3px;
    background-color: #000;
    margin-bottom: 0px;
    margin-top: -5px;
  }
}
