:root {
  --bg-header: url("../../assets/images/background_AI.png");
  --blue-words: #084266;
  --beije-words: #a28778;
}

.backgroundPicture {
  background-image: var(--bg-header);
  background-size: cover;
  height: 100vh;
  background-repeat: no-repeat;
}

.header {
  background-color: #f7f4f3;
  width: 100%;
  height: 100px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  opacity: 80%;
}

.logo-img {
  height: 75px;
  width: 75px;
  margin-top: 13px;
  margin-left: 25px;
}

.logo-text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-family: "Perpetua Titling MT Light", serif;
  font-weight: bold;
  margin-left: 15px;
}

.headerMenu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  width: 50%;
  margin-left: 25%;
}

.headerMenu a {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 30px;

  font-weight: bold;
  color: var(--blue-words);
  font-family: "Perpetua Titling MT Light", serif;
  font-weight: "bold";
  font-size: 16px;
  padding-bottom: 3px;
  border-bottom: 3px solid transparent;
  transition: color 0.2s ease, border-bottom 0.2s ease;
}

.headerMenu a:hover {
  color: var(--beije-words);
  cursor: pointer;
  border-bottom: 3px solid;
}

.description {
  background-color: #f7f4f3;
  opacity: 80%;
  border-radius: 10px;
  width: 50%;
  margin-left: 10%;
  margin-top: 5%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
}

.description h1 {
  font-family: "Perpetua Titling MT Light", sans-serif;
  font-weight: bold;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  flex: 1;
  margin-top: 25vh;
  color: white;
  background: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.507) 30%,
    rgba(0, 0, 0, 0.504) 70%,
    rgba(0, 0, 0, 0) 100%
  );
}

.main-title {
  font-size: 50px;
}

.logo-img-white {
  height: 150px;
  width: 150px;
}

/* Media queries */

@media screen and (max-width: 1050px) {
  .headerMenu {
    margin-left: 5%;
  }

  .logo-img {
    height: 60px;
    width: 60px;
    margin-top: 25px;
    margin-left: 2%;
  }

  .logo-text {
    font-size: 14px;
  }
}

@media screen and (max-width: 900px) {
  .main-title {
    font-size: 40px;
  }
  .logo-img-white {
    height: 100px;
    width: 100px;
  }

  .backgroundPicture {
    background-image: var(--bg-header);
    height: 100vh;
    background-repeat: no-repeat;
  }

  .headerMenu {
    height: 100px;
    width: 70%;
    margin-left: 0%;
  }

  .logo-text {
    font-size: 12px;
  }

  .logo-img {
    height: 50px;
    width: 50px;
    margin-top: 25px;
    margin-left: 2%;
  }
}

@media screen and (max-width: 700px) {
  .main-title {
    font-size: 30px;
  }
  .logo-img-white {
    height: 70px;
    width: 70px;
  }

  .backgroundPicture {
    background-image: var(--bg-header);
    height: 100vh;
    background-repeat: no-repeat;
  }
  .headerMenu {
    height: 100px;
    width: 70%;
    margin-left: 0%;
  }
  .headerMenu a {
    font-size: 14px;
  }

  .logo-text {
    font-size: 12px;
    width: 400px;
  }
}

@media screen and (max-width: 500px) {
  .logo-text {
    font-size: 12px;
    width: 60vw;
  }
}

@media screen and (max-width: 400px) {
  .backgroundPicture {
    background-image: var(--bg-header);
    height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .main-title {
    font-size: 20px;
    width: 50vw;
  }
  .logo-text {
    font-size: 12px;
    width: 50vw;
  }
}
