.footer-container {
  max-width: 100%;
  overflow-x: hidden;
  margin: 0 auto;
}

.footer-background {
  background-color: rgb(36, 36, 36);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.material-footer-icons.md-36 {
  font-size: 30px;
  margin-right: 10px;
  color: #bebebe;
}

.left-side-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  width: 100vw;
}

.right-side-footer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  width: 100vw;
}

.left-side-footer h1 {
  font-family: "Perpetua Titling MT Light", sans-serif;
  font-weight: bold;
  color: #ffffff;
  max-width: 80vw;
  margin-top: 50px;
  font-size: 24px; /* Adjust the font size for mobile screens */
}

.title-line {
  width: 180px;
  height: 3px;
  background-color: #ffffff;
  margin-bottom: 10px;
  margin-top: -5px;
}

.footer-text {
  width: 80vw;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  text-align: justify;
  text-justify: inter-word;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
  color: #bebebe;
}

.footer-text a {
  color: #bebebe;
}

.footer-text p {
  margin-bottom: 0px;
  align-items: left;
}

@media screen and (max-width: 1200px) {
  .left-side-footer h1 {
    font-size: 20px;
    max-width: 60vw;
  }
}

@media screen and (max-width: 1050px) {
  .footer-text {
    font-size: 15px;
    align-items: left;
  }
}

@media screen and (max-width: 600px) {
  .left-side-footer h1 {
    font-size: 18px;
    max-width: 80vw;
  }

  .footer-text {
    width: 80vw;
    font-size: 14px;
    justify-content: flex-start; /* Align the content to the start (left) */
  }

  .footer-text p {
    margin-bottom: 0px;
    align-self: flex-start; /* Align each paragraph to the start (left) */
  }

  .footer-text a {
    margin-bottom: 0px;
    align-self: flex-start; /* Align each paragraph to the start (left) */
  }
}
