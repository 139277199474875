.card {
  background-color: #f7f4f3;
  opacity: 80%;
  border-radius: 10px;
  width: 70vw;
  display: flex;
  flex-direction: column;
  padding: 30px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;
  transition: margin-top 0.5s ease-in-out;

  height: 2000;
  overflow: hidden;
  transition: margin-top 0.5s ease-in-out, height 0.5s ease-in-out;
}

.card-compressed {
  background-color: #f7f4f3;
  opacity: 80%;
  border-radius: 10px;
  width: 850px;
  margin-left: 5%;
  margin-top: 11%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 18px;

  transition: margin-top 0.5s ease-in-out, height 0.5s ease-in-out;
  height: 200px;
  overflow: hidden;
}

.card h1 {
  font-family: "Perpetua Titling MT Light", sans-serif;
  font-weight: bold;
  color: #084266;
  display: flex;
  justify-content: center;
  margin-top: -10px;
}

.card-compressed h1 {
  font-family: "Perpetua Titling MT Light", sans-serif;
  font-weight: bold;
  color: #084266;
}

@media screen and (max-width: 1050px) {
  .card-compressed {
    margin-left: 0;
    font-size: 16px;
    width: 80vw;
    margin-top: 15%;
  }

  .card {
    margin-left: 0;
    font-size: 16px;
    width: 80vw;
    margin-top: 0%;
  }

  .card-wrapper {
    display: flex;
    justify-content: center;
    height: auto;
  }

  .card h1 {
    font-size: 24px;
  }

  .card-compressed h1 {
    font-size: 24px;
  }
}

@media screen and (max-width: 700px) {
  .card h1 {
    font-size: 20px;
    margin-bottom: -7px;
  }

  .card-compressed h1 {
    font-size: 20px;
    margin-bottom: -7px;
  }

  .card-compressed {
    font-size: 13px;
    padding: 20px;
    width: 80vw;
    height: 20vh;
    margin-top: 10%;
  }

  .card {
    margin-top: 0%;
    font-size: 15px;
    padding: 20px;
    width: 80vw;
  }
}

@media screen and (max-width: 600px) {
  .card h1 {
    font-size: 20px;
    margin-bottom: -7px;
  }

  .card-compressed h1 {
    font-size: 20px;
    margin-bottom: -7px;
  }

  .card-compressed {
    height: 20%;
  }

  .card {
    margin-top: 0%;
    font-size: 15px;
    padding: 20px;
    width: 80vw;
  }
}

@media screen and (max-width: 460px) {
  .hide-paragraph {
    display: none;
  }

  .card {
    margin-top: 0%;
  }

  .card-compressed {
    height: 20%;
    margin-top: 50%;
  }
}
